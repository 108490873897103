<template>
  <ui-component-modal
    modalTitle=""
    :buttonLabelConfirm="'Delete'"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteOption"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <div>
          Are you sure you want to delete this option?
        </div>
        <div>
          <label class="checkbox">
            <input
              type="checkbox"
              @click.ctrl.left.exact="deleteOptionLocation"
              v-model="isDeleteConfirmed"
            />
            Yes, I'm sure
          </label>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import optionProvider from '@/providers/option'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    locationId: {
      type: Number,
      default: 0,
    },

    optionId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('optionStore', ['options']),
  },

  created() {},

  methods: {
    deleteOption() {
      if (this.locationId > 0) {
        this.deleteOptionLocation()
      } else {
        this.deleteOptionChannel()
      }
    },

    deleteOptionLocation() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true

        optionProvider.methods
          .deleteOptionLocation(self.optionId, self.locationId)
          .then((response) => {
            self.isDeletingSuccess = true

            let optionIndex = self.options.findIndex(
              (o) => o.Id === self.optionId
            )

            Vue.delete(self.options, optionIndex)

            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Option deleted!',
            })

            this.onClickCancel()
          })
          .catch(() => {
            self.isDeletingError = true
          })
          .finally(() => {
            self.isDeleting = false
          })
      }
    },

    deleteOptionChannel() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true

        optionProvider.methods
          .deleteOptionChannel(self.optionId, self.channel.Id)
          .then((response) => {
            self.isDeletingSuccess = true

            let optionIndex = self.options.findIndex(
              (o) => o.Id === self.optionId
            )

            Vue.delete(self.options, optionIndex)

            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Option deleted!',
            })

            this.onClickCancel()
          })
          .catch(() => {
            self.isDeletingError = true
          })
          .finally(() => {
            self.isDeleting = false
          })
      }
    },
  },
}
</script>
